import { useEffect, useMemo, useState } from 'react'
import { Stack, Grid, Typography, Button, Chip } from '@mui/material'
import { BoxInfoItem, ChipItem } from './common'
import Image, { StaticImageData } from 'next/image'
import { ButtonAnimation, CardAnimation } from '@/components/shared/animation'
import { useQuery } from 'react-query'
import { retrieveTokenMarketInfo } from '@/root/src/utils/apis/helper'
import { TokenMarketInfoModel } from '@/root/src/model/token-market-info'
import { calculateHoursBetweenDates, convertToClientTime, convertToCurrency } from '@/root/src/utils/helpers'

import useTrans from '@/root/src/hooks/useTrans'
import PriceIcon from '@/assets/token-price.png'
import MarketCapIcon from '@/assets/token-market-cap.png'
import CirculatingIcon from '@/assets/token-circulating.png'
import BurnIcon from '@/assets/burn-icon.png'
import TokenNameChipIcon from '@/assets/token-info-chip-icon.png'
import { useRouter } from 'next/router'
import { DATE_TIME_FORMAT_HH } from '@/root/src/constants'

interface TokenItemProps {
  type: 'native' | 'other'
  tokenName: string
  tokenSymbol: string
  tokenIcon: StaticImageData
  tokenDescription: string
  tokenUrl: string
  saleDate?: string
  saleEnd?: string
}
const TokenItem = (props: TokenItemProps) => {
  const { type, tokenName, tokenSymbol, tokenIcon, tokenDescription, tokenUrl, saleDate, saleEnd } = props
  const isNative = type === 'native'
  const [tokenInfo, setTokenInfo] = useState<TokenMarketInfoModel>({ price: 0, supply: 0, marketCap: 0 })
  const trans = useTrans()
  const { locale } = useRouter()
  const onSale = useMemo(() => {
    if (isNative) return false

    const currDate = convertToClientTime() as Date
    const hoursToStart = calculateHoursBetweenDates(currDate, convertToClientTime(saleDate) as Date)
    const hoursToEnd = calculateHoursBetweenDates(currDate, convertToClientTime(saleEnd) as Date)
    return hoursToStart <= 0 && hoursToEnd > 0
  }, [isNative, saleDate, saleEnd])

  const { data: dataToken } = useQuery(
    ['fetchRetrieveTokenMarketInfo', tokenSymbol],
    async () => {
      const result = await retrieveTokenMarketInfo(tokenSymbol)
      return result
    },
    { refetchOnWindowFocus: false },
  )
  useEffect(() => {
    if (!dataToken) return
    setTokenInfo(dataToken)
  }, [dataToken])

  const tokenDetail = useMemo(() => {
    const supplyData = convertToCurrency(tokenInfo.supply)
    return [
      {
        type: type,
        label: trans.home.token_info.price,
        value: tokenInfo.price > 0 ? `$${tokenInfo.price.toFixed(3)}` : '--',
        icon: PriceIcon,
      },
      {
        type: type,
        label: trans.home.token_info.market_cap,
        value:
          tokenInfo.marketCap > 0 ? `$${Math.round(tokenInfo.marketCap).toLocaleString()}` : '--',
        icon: MarketCapIcon,
      },
      {
        type: type,
        label: trans.home.token_info.circulating,
        value:
          tokenInfo.supply > 0
            ? `${supplyData.value} ${trans.home.token_info.unit[supplyData.unit]}`
            : '--',
        icon: CirculatingIcon,
      },
    ]

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trans, tokenInfo])

  return (
    <Grid
      container
      sx={{
        background: isNative ? '#FFC450' : '#052D41',
        color: isNative ? '#33393C' : '#FFFFFF',
      }}
      className="p-8 rounded-2xl h-full"
      data-testid={`${tokenSymbol}-detail`}
    >
      <Grid item xs={12} sm={7} md={8} order={{ xs: 2, sm: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} order={{ xs: 2, sm: 1 }}>
            <Stack direction="row" alignItems="center" gap={2} className="max-sm:mb-4">
              <ChipItem label={tokenName} color={isNative ? '#26272D' : '#FFFFF'} icon={TokenNameChipIcon} />
              {!isNative && (
                <a href={`/${locale}/properties`} className="text-ps-secondary underline max-sm:hidden">
                  {trans.home.token_info.view_all}
                </a>
              )}
            </Stack>
          </Grid>
          <Grid item xs={12} sm={12} order={{ xs: 1, sm: 2 }}>
            <Typography className="text-center sm:text-left font-bold text-3xl">
              {tokenSymbol}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} order={{ xs: 3, sm: 3 }}>
            <Typography className="text-center sm:text-left text-sm">{tokenDescription}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sm={5}
        md={4}
        order={{ xs: 1, sm: 2 }}
        className="flex flex-col justify-start items-center sm:items-end mb-6 token-icon"
      >
        <Image src={tokenIcon} alt="token-icon" width={136} />
      </Grid>
      <Grid item xs={12} sm={12} order={{ xs: 3, sm: 3 }} className="sm:mt-4">
        <Stack direction={{sm: 'column', md: 'row'}} justifyContent="space-between" alignItems="center" rowGap={3}>
          {isNative && (
            <ButtonAnimation delay={0.4} className="m-auto sm:m-0 w-fit mt-6">
              <Button
                component="a"
                target="_blank"
                href={tokenUrl}
                className="font-bold text-base normal-case rounded-full bg-[#052D41] py-3 px-6"
                sx={{
                  border: `1px solid ${isNative ? '#FFC450' : 'white'}`,
                  color: isNative ? '#F5A302' : '#FFFFFF',
                }}
              >
                {trans.home.token_info.buy_now}
              </Button>
            </ButtonAnimation>
          )}

          {!isNative && (
            !onSale ? (
              <>
                <ButtonAnimation delay={0.4} className="m-auto sm:m-0 w-fit mt-6">
                  <Button
                    component="a"
                    target="_blank"
                    href={tokenUrl}
                    className="font-bold text-base normal-case rounded-full bg-[#052D41] py-3 px-6"
                    sx={{
                      border: '1px solid white',
                      color: '#FFFFFF',
                    }}
                  >
                    {trans.home.token_info.learn_more}
                  </Button>
                </ButtonAnimation>
                <Stack direction="column" gap={0.5}>
                  <Typography className="text-sm">
                    {trans.home.token_info.sale_date}
                  </Typography>
                  <Typography className='font-semibold'>
                    {String(convertToClientTime(saleDate, DATE_TIME_FORMAT_HH))}
                  </Typography>
                </Stack>
              </>
            ) : (
              <>
                <Stack>
                  <Chip
                    className="text-[#FFE296] border-[#FFE296] border-solid border font-semibold py-4 rounded-full"
                    label={trans.common.status.on_sale}
                    icon={
                      <Image
                        className="inline ml-2 icon"
                        src={BurnIcon}
                        alt="coming soon"
                      />
                    }
                  />
                </Stack>
                <ButtonAnimation delay={0.4} className="m-auto sm:m-0 w-fit mt-6">
                  <Button
                    component="a"
                    target="_blank"
                    href={tokenUrl}
                    className="font-bold text-base normal-case rounded-full bg-ps-primary py-3 px-6 text-ps-secondary"
                  >
                    {trans.home.token_info.buy_now}
                  </Button>
                </ButtonAnimation>
              </>
            )
          )}
        </Stack>
      </Grid>
      <Grid item xs={12} sm={12} md={12} order={{ xs: 4, sm: 4 }} className="mt-6">
        <Grid container spacing={2}>
          {tokenDetail.map((item, index) => (
            <Grid key={index} item xs={index === 0 ? 12 : 6} sm={index === 0 ? 12 : 6} md={4}>
              <CardAnimation
                delay={index * 0.2}
                duration={0.55}
                className={`p-4 ${type === 'native' ? 'bg-[#ECB23F]' : 'bg-[#0B3E57]'} rounded-lg`}
              >
                <BoxInfoItem {...item} />
              </CardAnimation>
            </Grid>
          ))}
        </Grid>
      </Grid>
      {!isNative && (
        <Grid item xs={12} order={{sm: 5, xs: 5}} className="hidden max-sm:flex">
          <Stack direction="row" justifyContent="center" alignItems="center" className="py-3 w-full">
            <a href={`/${locale}/properties`} className="text-ps-secondary underline">
              {trans.home.token_info.view_all}
            </a>
          </Stack>
        </Grid>
      )}
    </Grid>
  )
}

export default TokenItem
