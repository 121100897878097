import { Stack, Typography } from '@mui/material'
import Image, { StaticImageData } from 'next/image'

export const ChipItem = ({
  label,
  color,
  icon,
}: {
  label: string
  color: string
  icon?: StaticImageData
}) => {
  return (
    <Typography
      className="m-auto sm:m-0 bg-white/[0.40] px-4 py-2 rounded-full flex items-center w-fit text-sm"
      color={color}
    >
      {icon && <Image className="inline mr-1 token-name-chip-icon" src={icon} height={24} alt="icon-detail" />}
      {label}
    </Typography>
  )
}

export const BoxInfoItem = ({
  type,
  label,
  value,
  icon,
}: {
  type: 'native' | 'other'
  label: string
  value: string
  icon?: StaticImageData
}) => {
  return (
    <Stack
      alignItems="center"
      sx={{
        color: type === 'native' ? '#000000' : '#FFFFFF',
      }}
      gap={1}
      data-testid={label}
    >
      <Stack direction="column" alignItems="center" gap={1}>
        {icon && (
          <Image className="hidden sm:block mr-1 box-info-icon" src={icon} height={28} alt="icon-detail" />
        )}
        <Typography className="font-bold text-xl" data-testid={`${label}-value`}>{value}</Typography>
      </Stack>
      <Typography className="text-sm" sx={{ color: type === 'native' ? '#000000B2' : '#FFFFFFCC' }}>
        {label}
      </Typography>
    </Stack>
  )
}
