import React, { useMemo } from 'react'
import { Container, Grid, Typography, Stack } from '@mui/material'
import SectionWrapper from '@/components/base/SectionWrapper'
import useTrans from '@/hooks/useTrans'
import Image from 'next/image'
import ProfitBackgroundImage from '@/assets/launch-notify-register.png'

import { motion } from 'framer-motion'
import { textVariant, zoomIn } from '@/root/src/utils/motion'
import { CardAnimation } from '@/components/shared/animation'

const ProfitItemCard = ({
  index,
  title,
  description,
}: {
  index: number
  title: string
  description: string
}) => (
  <Grid item xs={12} sm={4} className="flex flex-col items-start justify-start gap-8 mt-4 sm:mt-0">
    <CardAnimation
      delay={index * 0.2}
      duration={0.55}
      className="w-full h-full bg-[#F5F4F1] rounded-2xl border border-solid border-[#DBDFE3]"
    >
      <Stack className="relative px-6 pb-6 pt-14" direction="column" gap={2}>
        <Typography className="rounded-full bg-[#DDD] w-16 p-2 font-bold text-5xl text-[#212527] text-center absolute -top-8 left-6">
          {index + 1}
        </Typography>
        <Typography className="text-xl font-bold">{title}</Typography>
        <Typography dangerouslySetInnerHTML={{ __html: description }} />
      </Stack>
    </CardAnimation>
  </Grid>
)

function Profits() {
  const trans = useTrans()
  const listItems = useMemo(() => {
    return [
      {
        title: trans.home.profits.card_1.title,
        description: trans.home.profits.card_1.description,
      },
      {
        title: trans.home.profits.card_2.title,
        description: trans.home.profits.card_2.description,
      },
      {
        title: trans.home.profits.card_3.title,
        description: trans.home.profits.card_3.description,
      },
    ]
  }, [trans])
  return (
    <Container>
      <Grid container>
        <Grid xs={12} sm={6} item className="flex flex-col justify-center gap-8 py-6 pr-10">
          <motion.div variants={textVariant(0.2)}>
            <Typography className="text-3xl text-[#212527] font-bold">
              {trans.home.profits.title}
            </Typography>
          </motion.div>
          <motion.div variants={textVariant(0.4)}>
            <Typography>{trans.home.profits.description}</Typography>
          </motion.div>
        </Grid>
        <Grid item xs={12} sm={6} className="flex flex-col items-center justify-start">
          <motion.div variants={zoomIn(0.2, 0.5)} className="hidden sm:block">
            <Image width={287} src={ProfitBackgroundImage} alt="profit image" />
          </motion.div>
        </Grid>
      </Grid>
      <Grid container className="mt-4" spacing={3}>
        {listItems.map((item, index) => (
          <ProfitItemCard key={index} index={index} {...item} />
        ))}
      </Grid>
    </Container>
  )
}
export default SectionWrapper(Profits, 'home-profits')
