import View from './view'

const HomePage = () => {
  const computedProps = {}

  return <View {...computedProps} />
}

HomePage.displayName = 'HomeContainer'
export default HomePage
