import { motion } from 'framer-motion'
import { staggerContainer } from '@/utils/motion'

const SectionWrapper = (Component: any, idName: string) =>
  function HOC() {
    return (
      <motion.section
        variants={staggerContainer()}
        initial="hidden"
        whileInView="show"
        viewport={{ once: true, amount: 0.25 }}
        className="ax-w-7xl bg-ps-main relative z-0"
      >
        <span className="ps-hash-span" id={idName}>
          &nbsp;
        </span>

        <Component />
      </motion.section>
    )
  }

export default SectionWrapper
