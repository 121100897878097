import { FC } from 'react'
import { FCWithChildren } from './types'
import { motion } from 'framer-motion'
import { fadeIn, textVariant } from '@/root/src/utils/motion'

export const ButtonAnimation: FC<FCWithChildren & { delay: number, className?: string }> = ({ children, delay, className }) => (
  <motion.div
    variants={textVariant(delay)}
    className={className}
    whileHover={{ scale: 1.1 }}
    transition={{ type: 'spring', stiffness: 400, damping: 10 }}
  >
    {children}
  </motion.div>
)

export const CardAnimation: FC<
  FCWithChildren & { delay: number; duration: number; className?: string }
> = ({ children, delay, duration, className }) => (
  <motion.div variants={fadeIn('right', 'spring', delay, duration)} className={className}>
    {children}
  </motion.div>
)
