import { Seo } from '@/components/base'
import Home from '@/components/home'
import useTrans from '@/hooks/useTrans'
import { MainLayout } from '@/layouts/MainLayout'
import { PROPEASY_SITE_URL } from '@/utils/helpers'

const Index = () => {
  const trans = useTrans()
  return (
    <>
      {process.env.NEXT_PUBLIC_ENABLE_SEO === 'true' && (
        <Seo
          data={{
            title: trans.meta.home.title,
            description: trans.meta.home.description,
            thumbnailUrl: trans.meta.home.thumb,
            url: `${PROPEASY_SITE_URL}`,
          }}
        />
      )}
      <MainLayout>
        <Home />
      </MainLayout>
    </>
  )
}

export default Index

export const getServerSideProps = async (context: { locale: string }) => {
  return {
    props: {
      locale: context.locale || 'en',
    },
  }
}
