import useTrans from '@/hooks/useTrans'
import { useQuery } from 'react-query'
import { fetchBlogsList } from '@/utils/apis/blogs'
import { Container, Grid, Link, Stack, Typography } from '@mui/material'
import { motion } from 'framer-motion'
import { fadeIn } from '@/utils/motion'
import React from 'react'
import { useRouter } from 'next/router'
import BlogItem from '@/components/blogs/components/blog-item'
import { IBlog } from '@/components/blogs/types'

const BlogList = () => {
  const trans = useTrans()
  const { locale } = useRouter()
  const { data: blogsData, isLoading } = useQuery(
    ['fetchHomePageBlogs', locale],
    async () => {
      return await fetchBlogsList(locale || 'vi', 1, 2)
    },
    {
      refetchOnWindowFocus: false,
    },
  )

  if (isLoading || !blogsData) {
    return null
  }

  return (
    <Container>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        justifyContent="space-between"
        alignItems={{ xs: 'start', sm: 'end' }}
        gap={2}
      >
        <Stack gap={2}>
          <motion.div variants={fadeIn('bottom', 'spring', 0.1, 0.5)}>
            <Typography className="text-3xl sm:text-5xl font-bold">
              {trans.home.blog_list.title}
            </Typography>
          </motion.div>
        </Stack>
        <motion.div variants={fadeIn('bottom', 'spring', 0.1, 0.5)}>
          <Link className="font-bold" href="/blogs">
            {trans.home.blog_list.view_all}
          </Link>
        </motion.div>
      </Stack>
      <Stack>
        <Grid container spacing={2} className="mt-2 xs:px-4">
          {blogsData?.blogs?.map((blog: IBlog) => (
            <Grid item xs={12} sm={6} key={blog.slug}>
              <BlogItem {...blog} />
            </Grid>
          ))}
        </Grid>
      </Stack>
    </Container>
  )
}

export default BlogList
