import BigNumber from 'bignumber.js'

import { SaleType } from '@/components/shared/types'
import {
  calPriceSale,
  calSecondsToTime,
  calSecondsToWeeks,
  convertToCurrency,
  getMintAddressDetailUrl,
  minifyAddress,
} from '@/utils/helpers'
import { PropertyClient } from '@renec-foundation/propeasy-sdk'

export type PropertiesDetailModel = {
  error?: any
  id: string
  status: StatusModel
  chain_id: 'mainnet' | 'testnet'
  title: string
  image: string
  description: string
  address: string
  longitude: number
  latitude: number
  asset_type: string
  area_value: number
  area_unit: string
  valuation_value: number
  valuation_unit: string
  business_profit: number
  asset_profit: number
  utilities: string
  legal_documents: string
  token_name: string
  token_symbol: string
  token_address?: string
  token_logo_url: string

  created_at: string
  updated_at: string
  private_price: number
  private_sale_start_at: string
  private_sale_end_at: string
  remaining_private_tokens: number
  public_price: number
  public_sale_start_at: string
  public_sale_end_at: string
  remaining_public_tokens: number

  private_sale_status: StatusModel
  public_sale_status: StatusModel
  sale_status: StatusModel

  burn_start_at?: string
  burn_end_at?: string
  burnt_amount?: number

  private_expected_commission_amount?: number
  public_expected_commission_amount?: number
  commission_article_url?: string
  swap_url?: string

  represent_image_url: string
  annual_percentage_yield: number
  utility: string[]
  legal: string[]
  image_urls: string[]
  documents: DocumentModel[]

  uiValuationValue: string
  uiValuationUnit: string
  uiCurrency: string

  privateSale: SaleModeModel
  publicSale: SaleModeModel
  tokenSale: TokenInfoModal

  total_supply?: number
  private_supply?: number
  public_supply?: number
  private_minimum_purchase?: number
  public_minimum_purchase?: number
  private_lock_time_by_second?: number
  public_lock_time_by_second?: number
  prop_minimum_amount?: number

  last_buyer_rewards?: { public: number, private: number }
  public_last_buyer_address?: string
  private_last_buyer_address?: string
}

type DocumentModel = {
  filename: string
  url: string
}

type SaleModeModel = {
  id: SaleType
  status: StatusModel
  startTime: number
  endTime: number
  supplyAmount: number
  minimumPurchaseAmount: number
  minimumPlatformAmount: number

  tokenPrice: number
  discountPercentage: number
  tgePercentage: number

  referralCommissionAmount: number
  referralCommissionBoostAmount: number
  referralCommissionBoostThreshold: number

  totalPurchasedAmount: number
  unlockDuration: number
  unlockPeriod: number

  uiStartTime?: Date
  uiEndTime?: Date
  uiUnLockDuration?: number
  uiPriceSale: number
  uiPercentPurchased: number
}

type TokenInfoModal = {
  supplyAmount: number
  mintAccount?: string
  mintAccountUrl?: string
  openForSale: number
  liquidity: number
}

/* eslint-disable no-unused-vars */
export enum StatusModel {
  OnSale = 'on_sale',
  ComingSoon = 'comming_soon',
  Closed = 'closed',
  Burn = 'burning',
  All = 'all',
}

export const defaultPropertyClient = (): PropertyClient => {
  const propertyClient = {
    uiPropertyData: {
      privateSaleInfo: {
        commissionAmount: new BigNumber(0),
        discountPercentage: 0,
        endTime: 0,
        minimumPlatformAmount: new BigNumber(0),
        minimumPurchaseAmount: new BigNumber(0),
        referralCommissionAmount: new BigNumber(0),
        startTime: 0,
        supplyAmount: new BigNumber(0),
        tgePercentage: 0,
        tokenPrice: new BigNumber(0),
        totalPurchasedAmount: new BigNumber(0),
        unlockDuration: 0,
        unlockPeriod: 0,
      },
      publicSaleInfo: {
        commissionAmount: new BigNumber(0),
        commissionBoostAmount: new BigNumber(0),
        endTime: 0,
        minimumPurchaseAmount: new BigNumber(0),
        referralCommissionAmount: new BigNumber(0),
        referralCommissionBoostAmount: new BigNumber(0),
        referralCommissionBoostThreshold: new BigNumber(0),
        startTime: 0,
        supplyAmount: new BigNumber(0),
        tokenPrice: new BigNumber(0),
        totalPurchasedAmount: new BigNumber(0),
      },
      propertyMintAccount: undefined,
      totalSupply: new BigNumber(0),
    },
    propertyMintAccount: undefined,
  } as any
  return propertyClient
}

export const combinePropertyData = (
  propertyModel: PropertiesDetailModel,
  propertyClient: PropertyClient,
) => {
  const newProperty: PropertiesDetailModel = {
    ...propertyModel,
  }

  // Private Sale
  newProperty.privateSale = {} as SaleModeModel
  newProperty.privateSale.id = SaleType.Private
  newProperty.privateSale.status = newProperty.private_sale_status
  newProperty.privateSale.startTime = propertyClient.uiPropertyData.privateSaleInfo.startTime
  newProperty.privateSale.uiStartTime = propertyClient.propertyMintAccount ?
    calSecondsToTime(newProperty.privateSale.startTime) :
    new Date(newProperty.private_sale_start_at)
  newProperty.privateSale.endTime = propertyClient.uiPropertyData.privateSaleInfo.endTime
  newProperty.privateSale.uiEndTime = propertyClient.propertyMintAccount ?
    calSecondsToTime(newProperty.privateSale.endTime) :
    new Date(newProperty.private_sale_end_at)
  newProperty.privateSale.supplyAmount = propertyClient.propertyMintAccount ?
    Number(propertyClient.uiPropertyData.privateSaleInfo.supplyAmount) :
    (newProperty.private_supply || 0)
  newProperty.privateSale.minimumPurchaseAmount = propertyClient.propertyMintAccount ?
    Number(propertyClient.uiPropertyData.privateSaleInfo.minimumPurchaseAmount) :
    (newProperty.private_minimum_purchase || 0)
  newProperty.privateSale.minimumPlatformAmount = propertyClient.propertyMintAccount ?
    Number(propertyClient.uiPropertyData.privateSaleInfo.minimumPlatformAmount) :
    (newProperty.prop_minimum_amount || 0)

  newProperty.privateSale.totalPurchasedAmount = Number(
    propertyClient.uiPropertyData.privateSaleInfo.totalPurchasedAmount,
  )
  newProperty.privateSale.tokenPrice = propertyClient.propertyMintAccount ?
    Number(propertyClient.uiPropertyData.privateSaleInfo.tokenPrice) :
    (newProperty.private_price || 0)
  const configDiscountPercentage = (newProperty.private_price && newProperty.public_price) ?
    ((newProperty.public_price - newProperty.private_price) * 100.0 / newProperty.public_price) : 0
  newProperty.privateSale.discountPercentage = propertyClient.propertyMintAccount ?
    propertyClient.uiPropertyData.privateSaleInfo.discountPercentage :
    configDiscountPercentage

  newProperty.privateSale.tgePercentage =
    propertyClient.uiPropertyData.privateSaleInfo.tgePercentage
  newProperty.privateSale.unlockDuration =
    propertyClient.uiPropertyData.privateSaleInfo.unlockDuration
  newProperty.privateSale.uiUnLockDuration = propertyClient.propertyMintAccount ?
    calSecondsToWeeks(newProperty.privateSale.unlockDuration) :
    calSecondsToWeeks(newProperty.private_lock_time_by_second || 0)
  newProperty.privateSale.uiPriceSale = calPriceSale(0, newProperty.privateSale.tokenPrice)
  newProperty.privateSale.uiPercentPurchased = Math.round(
    (newProperty.privateSale.totalPurchasedAmount / newProperty.privateSale.supplyAmount) * 100,
  )

  newProperty.privateSale.referralCommissionAmount = Number(
    propertyClient.uiPropertyData.privateSaleInfo.referralCommissionAmount,
  )
  newProperty.privateSale.referralCommissionBoostAmount = 0
  newProperty.privateSale.referralCommissionBoostThreshold = 0

  if (
    propertyClient.propertyMintAccount && Math.round(
      newProperty.privateSale.supplyAmount - newProperty.privateSale.totalPurchasedAmount,
    ) <= 0
  )
    newProperty.privateSale.status = StatusModel.Closed

  // Public Sale
  newProperty.publicSale = {} as SaleModeModel
  newProperty.publicSale.id = SaleType.Public
  newProperty.publicSale.status = newProperty.public_sale_status
  newProperty.publicSale.startTime = propertyClient.uiPropertyData.publicSaleInfo.startTime
  newProperty.publicSale.uiStartTime = propertyClient.propertyMintAccount ?
    calSecondsToTime(newProperty.publicSale.startTime) :
    new Date(newProperty.public_sale_start_at)
  newProperty.publicSale.endTime = propertyClient.uiPropertyData.publicSaleInfo.endTime
  newProperty.publicSale.uiEndTime = propertyClient.propertyMintAccount ?
    calSecondsToTime(newProperty.publicSale.endTime) :
    new Date(newProperty.public_sale_end_at)
  newProperty.publicSale.supplyAmount = propertyClient.propertyMintAccount ?
    Number(propertyClient.uiPropertyData.publicSaleInfo.supplyAmount) :
    (newProperty.public_supply || 0)
  newProperty.publicSale.minimumPurchaseAmount = propertyClient.propertyMintAccount ?
    Number(propertyClient.uiPropertyData.publicSaleInfo.minimumPurchaseAmount) :
    (newProperty.public_minimum_purchase || 0)

  newProperty.publicSale.totalPurchasedAmount = Number(
    propertyClient.uiPropertyData.publicSaleInfo.totalPurchasedAmount,
  )
  newProperty.publicSale.tokenPrice = newProperty.privateSale.tokenPrice = propertyClient.propertyMintAccount ?
    Number(propertyClient.uiPropertyData.publicSaleInfo.tokenPrice) :
    (newProperty.public_price || 0)

  newProperty.publicSale.referralCommissionAmount = Number(
    propertyClient.uiPropertyData.publicSaleInfo.referralCommissionAmount,
  )
  newProperty.publicSale.referralCommissionBoostAmount = Number(
    propertyClient.uiPropertyData.publicSaleInfo.referralCommissionBoostAmount,
  )
  newProperty.publicSale.referralCommissionBoostThreshold = Number(
    propertyClient.uiPropertyData.publicSaleInfo.referralCommissionBoostThreshold,
  )

  newProperty.publicSale.minimumPlatformAmount = 0
  newProperty.publicSale.discountPercentage = 0
  newProperty.publicSale.tgePercentage = 0
  newProperty.publicSale.unlockDuration = 0
  newProperty.publicSale.uiUnLockDuration = 0
  newProperty.publicSale.uiPriceSale = calPriceSale(
    newProperty.publicSale.discountPercentage,
    newProperty.publicSale.tokenPrice,
  )
  newProperty.publicSale.uiPercentPurchased = Math.round(
    (newProperty.publicSale.totalPurchasedAmount / newProperty.publicSale.supplyAmount) * 100,
  )
  if (
    propertyClient.propertyMintAccount && Math.round(newProperty.publicSale.supplyAmount - newProperty.publicSale.totalPurchasedAmount) <=
    0
  )
    newProperty.publicSale.status = StatusModel.Closed

  // Update
  const valuationFormat = convertToCurrency(newProperty.valuation_value)
  newProperty.uiValuationValue = valuationFormat.value
  newProperty.uiValuationUnit = valuationFormat.unit
  newProperty.uiCurrency = newProperty.valuation_unit.toUpperCase()
  newProperty.status = newProperty.sale_status
  newProperty.description = propertyModel.description
    .replace(/\r/g, '')
    .replace(/\n/g, '<br/>')
    .trim()
  newProperty.image = newProperty.represent_image_url
  newProperty.business_profit = newProperty.business_profit || 0
  newProperty.asset_profit = newProperty.asset_profit || 0
  newProperty.annual_percentage_yield = newProperty.business_profit + newProperty.asset_profit

  const utilities = newProperty.utilities.replace('\r', '').split('\n')
  newProperty.utility = []
  utilities.forEach((item) => {
    if (item.length > 0) newProperty.utility.push(item.trim())
  })

  const legal_documents = newProperty.legal_documents.replace('\r', '').split('\n')
  newProperty.legal = []
  legal_documents.forEach((item) => {
    if (item.length > 0) newProperty.legal.push(item.trim())
  })

  newProperty.tokenSale = {} as TokenInfoModal
  newProperty.tokenSale.supplyAmount = propertyClient.propertyMintAccount ?
    Number(propertyClient.uiPropertyData.totalSupply) :
    (newProperty.total_supply || 0)
  newProperty.tokenSale.mintAccount = propertyClient.propertyMintAccount ?
    minifyAddress(propertyClient.propertyMintAccount.toBase58()) :
    undefined
  newProperty.tokenSale.mintAccountUrl = propertyClient.propertyMintAccount ?
    getMintAddressDetailUrl(propertyClient.propertyMintAccount.toBase58()) :
    undefined
  newProperty.tokenSale.openForSale =
    newProperty.privateSale.supplyAmount + newProperty.publicSale.supplyAmount
  newProperty.tokenSale.liquidity =
    newProperty.tokenSale.supplyAmount - newProperty.tokenSale.openForSale
  return newProperty
}

export const msgErrors = (error: string, renecBalance?: number) => {
  const errArray = String(error).split(' ')
  const errorCode = errArray[errArray.length - 1]
  if (errorCode.startsWith('0x17')) return errorCode
  if (error.includes('Blockhash not found')) return 'transaction_timeout'
  if (error.includes('Attempt to debit an account') || (error.includes('AccountNotFound') && renecBalance && renecBalance === 0))
    return 'no_record_credit'
  return '0x1769'
}
