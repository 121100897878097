import api from '@/utils/apis/index'

export const fetchBlogsList = async (lang: string, page: number, per: number) => {
  const { data } = await api.get('/blogs', {
    params: { lang, page, per, workspace: 'propeasy' },
  })

  return data
}

export const fetchBlogDetail = async (slug: string) => {
  const { data } = await api.get(`/blog/${slug}`)
  return data
}
