import React, {
  useCallback,
  useMemo,
} from 'react'

import Image, { StaticImageData } from 'next/image'

import BurnIcon from '@/assets/burn-icon.png'
import ComingSoonIcon from '@/assets/coming-soon-icon.png'
import OnSaleIcon from '@/assets/on-sale-icon.png'
import useTrans from '@/hooks/useTrans'
import { StatusModel } from '@/root/src/model/properties-detail'
import { staticImportIcon } from '@/root/src/utils/static-import-icon'
import {
  Button,
  Chip,
} from '@mui/material'

interface StatusItemProps {
  status: StatusModel
  currentStatus?: StatusModel
  icon?: string | null | StaticImageData
  handleActiveStatus?: (status: StatusModel) => void
  burnStartDate?: string
  burnEndDate?: string
  textSize?: 'base' | 'sm' | 'xs'
}

export const StatusChip = (props: StatusItemProps) => {
  const { status, burnStartDate, burnEndDate, textSize = 'base' } = props
  const trans = useTrans()

  const label = trans.common.status[status]
  const burnLabel = useMemo(() => {
    if (status !== StatusModel.Burn) return
    if (!burnStartDate || !burnEndDate) return trans.common.status.burnFilterLabel

    return trans.common.status.burning(burnStartDate, burnEndDate)
  }, [burnEndDate, burnStartDate, status, trans])
  const iconSize = textSize === 'base' ? 20 : 16
  const paddingSize = textSize === 'base' ? 'py-5' : 'py-3'

  const statusChip = useMemo(() => {
    switch (status) {
      case StatusModel.OnSale:
        return (
          <Chip
            className={`bg-[#FED57E] text-${textSize} font-semibold ${paddingSize} rounded-full`}
            label={label}
            icon={
              <Image
                className="inline ml-2"
                src={OnSaleIcon}
                height={iconSize}
                alt="on sale"
              />
            }
          />
        )
      case StatusModel.ComingSoon:
        return (
          <Chip
            className={`bg-[#F4ECE4] text-${textSize} font-semibold ${paddingSize} rounded-full`}
            label={label}
            icon={
              <Image
                className="inline ml-2"
                src={ComingSoonIcon}
                height={iconSize}
                alt="coming soon"
              />
            }
          />
        )
      case StatusModel.Burn:
        return (
          <Chip
            className={`bg-[#ED99997A]/[0.48] text-${textSize} font-semibold ${paddingSize} rounded-full`}
            label={`${burnLabel}`}
            icon={
              <Image
                className="inline ml-2"
                src={BurnIcon}
                height={iconSize}
                alt="burn"
              />
            }
          />
        )
      default:
        return (
          <Chip className={`bg-[#E3E3E3] text-${textSize} font-semibold ${paddingSize} rounded-full`} label={label} />
        )
    }
  }, [status, textSize, paddingSize, label, iconSize, burnLabel])

  return statusChip
}

function StatusItem(props: StatusItemProps) {
  const { status, icon, handleActiveStatus, currentStatus } = props
  const trans = useTrans()

  const handleClick = useCallback(() => {
    if (handleActiveStatus) handleActiveStatus(status)
  }, [status, handleActiveStatus])

  return (
    <Button
      disableRipple
      sx={{
        backgroundColor: currentStatus === status ? '#A7B6BE !important' : '#EEF0F1 !important',
        color: currentStatus === status ? '#04202E' : '#677C86',
        '&:hover': {
          backgroundColor: '#A7B6BE !important',
          color: '#04202E',
        },
      }}
      className="py-3 px-4 rounded-3xl font-semibold text-sm normal-case"
      onClick={handleClick}
    >
      { icon && (
        typeof icon === 'string' ? (
          <Image className="inline mr-1" src={staticImportIcon(icon)} height={24} alt={icon} />
        ) : (
          <Image
            className="inline mr-1"
            src={icon as StaticImageData}
            height={18}
            alt="coming soon"
          />
        )
      )}
      {status === StatusModel.Burn ? trans.common.status.burnFilterLabel : trans.common.status[status]}
    </Button>
  )
}

export default StatusItem
