import {
  memo,
  useEffect,
} from 'react'

import { track } from '@/utils/amplitude'
import { Stack } from '@mui/material'

import BlogList from './components/blog-list'
import FeaturedList from './components/featured-list'
import Information from './components/information'
import Introduction from './components/introduction'
import Profits from './components/profits'
import TokenInfo from './components/token-info'

const View = () => {
  useEffect(() => {
    track('visit_home_page')
  }, [])

  return (
    <Stack gap={8}>
      <Stack>
        <Introduction />
        <FeaturedList />
        <Information />
      </Stack>
      <Profits />
      <TokenInfo />
      <BlogList />
    </Stack>
  )
}

const HomeWithMemo = memo(View)
HomeWithMemo.displayName = 'HomeView'
export default HomeWithMemo
