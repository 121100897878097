import {
  Grid,
  Skeleton,
  Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material'

const SkeletonFeaturedList = () => {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <>
      <Grid item sm={18} md={8} alignItems="center" className="w-full">
        <Stack>
          <Skeleton variant="text" className="text-[32px] leading-[64px]" />
          <Skeleton variant="text" className="font-normal text-base" />
          <Skeleton variant="text" className="font-normal text-base" />
        </Stack>
      </Grid>
      {[...Array(2).keys()].map(idx => (
        <Grid item key={idx} xs={18} md={5}>
          <Skeleton variant="rectangular" className="h-[104px] rounded-lg"/>
        </Grid>
      ))}

      {  isSmallScreen ? (
        [...Array(3).keys()].map(idx => (
          <Grid item xs={18} key={idx}>
            <Skeleton variant="rectangular" height={200} className="rounded-lg p-4"/>
          </Grid>
        ))
      ) : (
        <Grid item md={18} className="mt-8">
          <Stack gap={1}>
            <Skeleton variant="rectangular" className="w-full" height={16} />
            {[...Array(3).keys()].map(idx => (
              <Stack key={idx} className='p-2 text-sm' direction="row" gap={2}>
                {[...Array(5).keys()].map(idx => (
                  <Skeleton key={idx} variant="text" className="py-2 w-1/5" height={16} />
                ))}
              </Stack>
            ))}
          </Stack>
        </Grid>
      ) }
    </>
  )
}

export default SkeletonFeaturedList
