import 'react-responsive-carousel/lib/styles/carousel.min.css'

import React, {
  useCallback,
  useState,
} from 'react'

import { motion } from 'framer-motion'
import { StaticImageData } from 'next/image'
import { Carousel } from 'react-responsive-carousel'

import ApsIcon from '@/assets/aps-icon.png'
import FiatGastIcon from '@/assets/fiat-gast-icon.png'
import FiatGastTestIcon from '@/assets/fiat-gast-test-icon.png'
import FiatPropIcon from '@/assets/fiat-prop-icon.png'
import FiatPropTestIcon from '@/assets/fiat-prop-test-icon.png'
import Plus1Icon from '@/assets/plus1-icon.png'
import SectionWrapper from '@/components/base/SectionWrapper'
import withNoSsr from '@/components/shared/with-no-ssr'
import useTrans from '@/hooks/useTrans'
import {
  NEMO_SITE_URL,
  SITE_URL,
} from '@/root/src/constants'
import { useIsMobileDevice } from '@/root/src/hooks/use-is-mobile-device'
import {
  isBeta,
  isRunningInCypress,
} from '@/root/src/utils/helpers'
import { textVariant } from '@/root/src/utils/motion'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import {
  Button,
  Container,
  IconButton,
  Stack,
  styled,
  Typography,
} from '@mui/material'

import TokenItem from './token-item'

type TokenInfoObject = {
  type: 'native' | 'other',
  url: string,
  icon: StaticImageData
  sale_date?: string
  sale_end?: string
}
type TokenInfoMappingKeys = 'prop' | 'gast' | 'plus1' | 'aps'

const TOKEN_INFO_MAPPING: Record<TokenInfoMappingKeys, TokenInfoObject> = {
  prop: {
    type: 'native',
    url: `${NEMO_SITE_URL}/reUSD_PROP`,
    icon: isBeta ? FiatPropTestIcon : FiatPropIcon,
  },
  gast: {
    type: 'other',
    url: `${SITE_URL}/properties/detail/GAST`,
    icon: isBeta ? FiatGastTestIcon : FiatGastIcon,
    sale_date: '2024-01-08 14:00:00',
    sale_end: '2024-01-18 16:00:00',
  },
  plus1: {
    type: 'other',
    url: `${SITE_URL}/properties/detail/PLUS1`,
    icon: Plus1Icon,
    sale_date: '2024-03-04 14:00:00',
    sale_end: '2024-03-14 19:00:00',
  },
  aps: {
    type: 'other',
    url: `${SITE_URL}/properties/detail/APS`,
    icon: ApsIcon,
    sale_date: '2024-04-29 14:00:00',
    sale_end: '2024-05-13 19:00:00',
  },
}
interface TokenInfoCarouselControlProps {
  total: number
  selected?: number
  onSelect: (idx: number) => void
}
const nextCarouselIndex = (action: string, lastIndex: number, total: number) => {
  let offset = 1
    if (action === 'back') {
      offset = lastIndex === 0 ? -(total - 1) : -1
    }
    return Math.abs((lastIndex + offset) % total)
}
const TokenInfoCarousel = styled(Carousel)(() => {
  const isMobile = useIsMobileDevice()

  return {
    '& .carousel-slider': {
      '& .slider': {
        width: isMobile ? '100%' : '50%',
      },
      '& li.slide': {
        '&.previous,&.selected': {
          transition: 'opacity 0.3s ease-in-out',
          transitionDelay: '0.2s',
        },
        '&.previous': {
          opacity: 0,
        },
        '&.selected': {
          opacity: 1,
        },
        padding: isMobile ? '6px 0 0 6px' : '16px 0 0 16px',
        '& .box-info-icon': {
          width: '28px !important',
          height: '28px !important',
        },
        '& .token-icon img': {
          width: '136px !important',
        },
        '& .icon': {
          width: '20px !important',
        },
        '& .token-name-chip-icon': {
          width: '24px !important',
        },
      },
    },
  }
})

const TokenInfoCarouselControl = ({ total, onSelect, selected = -1 }: TokenInfoCarouselControlProps) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(selected)
  const dotBackground = useCallback((index: number) => {
    if (index === selectedIndex) return 'bg-ps-secondary'

    return 'bg-[#D9D9D9]'
  }, [selectedIndex])
  const onClickDot = (idx: number) => {
    setSelectedIndex(idx)
    onSelect(idx)
  }
  const onClickAction = (action: string) => {
    setSelectedIndex(prevIndex => {
      const nextIndex = nextCarouselIndex(action, prevIndex, total)
      onSelect(nextIndex)
      return nextIndex
    })
  }

  const actionButton = (action: string) => {
    const btnClassName = 'rounded-[24px] bg-[#F0F0EF] w-12'
    const iconClassName = 'w-4 h-4'
    return (
      <IconButton className={btnClassName} onClick={() => onClickAction(action)}>
        {action === 'back' ?
          <ArrowBackIcon className={iconClassName} /> :
          <ArrowForwardIcon className={iconClassName} />
        }
      </IconButton>
    )
  }

  return (
    <Stack direction="row" justifyContent="center" alignItems="center" gap={3}>
      {actionButton('back')}
      <Stack direction="row">
        {[...Array(total).keys()].map(idx => (
          <Button
            key={idx}
            onClick={() => onClickDot(idx)}
            className={
              'transition-opacity ease-in duration-150 p-0 min-w-0 min-h-0 shadow-inner ' +
              `w-2 h-2 rounded-full cursor-pointer max-sm:mx-1 mx-2 ${dotBackground(idx)}`
            }
          />
        ))}
      </Stack>
      {actionButton('forward')}
    </Stack>
  )
}

function TokenInfo() {
  const trans = useTrans()
  const tokenInfoTrans = trans.home.token_info as any
  const [tokenIndex, setTokenIndex] = useState(0)

  return (
    <Container className="md:mb-12">
      <Stack gap={3}>
        <motion.div variants={textVariant(0.2)}>
          <Typography className="text-3xl text-[#212527] text-center font-bold">
            {trans.home.token_info.title}
          </Typography>
        </motion.div>
        <TokenInfoCarousel
          autoPlay={!isRunningInCypress}
          infiniteLoop
          interval={2500}
          showThumbs={false}
          showStatus={false}
          showIndicators={false}
          showArrows={false}
          selectedItem={tokenIndex}
          className="token-info-carousel"
        >
          {Object.keys(TOKEN_INFO_MAPPING).map((tokenName: string) => {
            const tokenInfoObj = TOKEN_INFO_MAPPING[tokenName as TokenInfoMappingKeys]
            const transObj = tokenInfoTrans[tokenName]
            if (!transObj) return <></>

            return (
              <TokenItem
                key={tokenName}
                type={tokenInfoObj.type}
                tokenName={transObj.name}
                tokenSymbol={transObj.symbol}
                tokenDescription={transObj.description}
                tokenIcon={tokenInfoObj.icon}
                tokenUrl={tokenInfoObj.url}
                saleDate={tokenInfoObj.sale_date}
                saleEnd={tokenInfoObj.sale_end}
              />
            )
          })}
        </TokenInfoCarousel>
        <TokenInfoCarouselControl
          selected={tokenIndex}
          total={Object.keys(TOKEN_INFO_MAPPING).length}
          onSelect={setTokenIndex}
        />
      </Stack>
    </Container>
  )
}

export default SectionWrapper(withNoSsr(TokenInfo), 'home-token-info')
