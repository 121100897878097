import React from 'react'
import { CardMedia, Grid, Typography, Box } from '@mui/material'
import { IBlog } from '@/components/blogs/types'
import { useRouter } from 'next/router'

const BlogItem = (props: IBlog) => {
  const { title, description, banner, public_date, slug } = props
  const router = useRouter()

  const handleClick = () => {
    router.push({ pathname: `/blogs/${slug}` })
  }

  return (
    <Grid
      container
      className="rounded-2xl shadow-slate-50 bg-[#FFFFFF] cursor-pointer"
      data-testid="blog-item"
      onClick={handleClick}
    >
      <CardMedia
        component="img"
        alt="demo-1"
        image={banner}
        className="rounded-2xl object-contain"
      />
      <Box className="pb-6 pt-4 px-4 h-[260px]">
        <Typography className="text-sm text-[#33393C]">{public_date}</Typography>
        <Typography className="text-2xl text-[#000] font-semibold line-clamp-3 my-4 ">{title}</Typography>
        <Typography className="text-base text-[#33393C] line-clamp-3">{description}</Typography>
      </Box>
    </Grid>
  )
}

export default React.memo(BlogItem)
