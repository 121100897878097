import React, { useMemo } from 'react'
import { Container, Grid, Box, Typography, Stack } from '@mui/material'
import SectionWrapper from '@/components/base/SectionWrapper'
import useTrans from '@/hooks/useTrans'
import Image, { StaticImageData } from 'next/image'
import InfoBackgroundImage from '@/assets/home-information-background.png'
import InfoIcon1 from '@/assets/home-information-icon-1.png'
import InfoIcon2 from '@/assets/launch-home-information-icon-2.png'
import InfoIcon3 from '@/assets/home-information-icon-2.png'
import InfoIcon4 from '@/assets/home-information-icon-3.png'
import InfoIcon5 from '@/assets/launch-home-information-icon-5.png'

import { motion } from 'framer-motion'
import { fadeIn, textVariant, zoomIn } from '@/root/src/utils/motion'

const InfoItemCard = ({
  index,
  title,
  description,
  icon,
}: {
  index: number
  title: string
  description: string
  icon: StaticImageData
}) => (
  <Grid
    item
    xs={12}
    sm={index < 2 ? 6 : 4}
    className="flex flex-col items-start justify-start gap-8"
  >
    <motion.div
      variants={fadeIn('right', 'spring', index * 0.2, 0.55)}
      className="w-full h-full bg-[#FFF]/[0.12] rounded-2xl px-6 py-4"
    >
      <Stack direction="column" gap={2}>
        <Image className="h-fit" src={icon} alt="icon" width={64} />
        <Stack gap={1.5}>
          <Typography className="text-xl font-bold">{title}</Typography>
          <Typography>{description}</Typography>
        </Stack>
      </Stack>
    </motion.div>
  </Grid>
)

function Information() {
  const trans = useTrans()
  const listItems = useMemo(() => {
    return [
      {
        title: trans.home.information.card_1.title,
        description: trans.home.information.card_1.description,
        icon: InfoIcon1,
      },
      {
        title: trans.home.information.card_2.title,
        description: trans.home.information.card_2.description,
        icon: InfoIcon2,
      },
      {
        title: trans.home.information.card_3.title,
        description: trans.home.information.card_3.description,
        icon: InfoIcon3,
      },
      {
        title: trans.home.information.card_4.title,
        description: trans.home.information.card_4.description,
        icon: InfoIcon4,
      },
      {
        title: trans.home.information.card_5.title,
        description: trans.home.information.card_5.description,
        icon: InfoIcon5,
      },
    ]
  }, [trans])
  return (
    <Box className="bg-[#052D41] text-white py-10">
      <Container>
        <Grid container>
          <Grid xs={12} sm={6} item className="flex flex-col justify-center gap-8 py-6 pr-10">
            <motion.div variants={textVariant(0.2)}>
              <Typography className="text-3xl font-bold">{trans.home.information.title}</Typography>
            </motion.div>
            <motion.div variants={textVariant(0.4)}>
              <Typography className="">{trans.home.information.description}</Typography>
            </motion.div>
          </Grid>
          <Grid item xs={12} sm={6} className="flex flex-col items-center justify-start">
            <motion.div variants={zoomIn(0.2, 0.5)} className="hidden sm:block">
              <Image width={287} src={InfoBackgroundImage} alt="question answer image" />
            </motion.div>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          {listItems.map((item, index) => (
            <InfoItemCard key={index} index={index} {...item} />
          ))}
        </Grid>
      </Container>
    </Box>
  )
}
export default SectionWrapper(Information, 'home-information')
