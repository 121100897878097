/* eslint-disable no-unused-vars */
import { ReactNode } from 'react'

export interface FCWithChildren {
  children: ReactNode
}

export enum SaleType {
  Private = 'private',
  Public = 'public',
}

export type PurchaseInfo = {
    saleType: SaleType
    propertyTitle: string
    tokenName: string
    tokenSymbol: string
    tokenPrice: number
    amount: number
    qtyOfToken: number
    commissionAmount: number
    minimumPurchase: number
    maximumPurchase: number
    uiUnLockDuration?: number
    tgePercentage?: number
    lockEndDate?: Date
}
