import React, {
  useMemo,
  useState,
} from 'react'

import { motion } from 'framer-motion'
import Image from 'next/image'

import BitcoinistIcon from '@/assets/bitcoinist.png'
import IntroBackgroundImageDesktop from '@/assets/home-introduction-background.desktop.png'
import IntroBackgroundImageMobile from '@/assets/home-introduction-background.mobile.png'
import NewBtcIcon from '@/assets/news-btc.png'
import TechBullionIcon from '@/assets/tech-bullion.png'
import VerichainsIcon from '@/assets/verichains.png'
import SectionWrapper from '@/components/base/SectionWrapper'
import YoutubeEmbed from '@/components/base/YoutubeEmbed'
import { ButtonAnimation } from '@/components/shared/animation'
import withNoSsr from '@/components/shared/with-no-ssr'
import useTrans from '@/hooks/useTrans'
import { useIsMobileDevice } from '@/root/src/hooks/use-is-mobile-device'
import { textVariant } from '@/root/src/utils/motion'
import {
  Box,
  Button,
  Container,
  Dialog,
  Grid,
  Link,
  Stack,
  Typography,
} from '@mui/material'

const styles = {
  heroContainer: {
    height: '100vh',
    background: `url(${IntroBackgroundImageDesktop.src})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
  },
}

const featureInMapping = [
  {
    name: 'bitcoinist-partner',
    link: 'https://bitcoinist.com/three-new-products-unveiled-by-renec-blockchain-opening-up-profitable-investing-opportunities/',
    icon: BitcoinistIcon,
  },
  {
    name: 'news-btc-partner',
    link: 'https://www.newsbtc.com/press-releases/three-new-products-unveiled-by-renec-blockchain-opening-up-profitable-investing-opportunities/',
    icon: NewBtcIcon,
  },
  {
    name: 'tech-bullion-partner',
    link: 'https://techbullion.com/three-new-products-unveiled-by-renec-blockchain-opening-up-profitable-investing-opportunities/',
    icon: TechBullionIcon,
  },
]

const HeroText = () => {
  const [playVideo, setPlayVideo] = useState(false)
  const trans = useTrans()
  const isMobile = useIsMobileDevice()

  return (
    <>
      <Stack className="items-center px-3 text-ps-dark-blue sm:items-start" gap={2}>
        <motion.div variants={textVariant(0.2)}>
          <Typography
            className="text-[2rem] sm:text-[2.5rem] font-bold text-center sm:text-left text-white"
            dangerouslySetInnerHTML={{ __html: trans.home.introduction.hero_title }}
          />
        </motion.div>
        <motion.div variants={textVariant(0.4)}>
          <Typography
            className="text-base text-center text-white sm:text-left"
            dangerouslySetInnerHTML={{ __html: trans.home.introduction.hero_description }}
          />
        </motion.div>
        <Stack direction="row" gap={2}>
          <ButtonAnimation delay={0.6} className="mt-4 w-fit">
            <Button
              component="a"
              href="/properties"
              disableRipple
              className="px-4 py-4 text-base font-semibold text-center text-white normal-case rounded-full bg-ps-secondary sm:px-12"
            >
              {trans.home.introduction.hero_view_properties}
            </Button>
          </ButtonAnimation>
          <ButtonAnimation delay={0.6} className="mt-4 w-fit">
            <Button
              onClick={() => setPlayVideo(true)}
              disableRipple
              className="px-4 py-4 text-base font-semibold text-center text-white normal-case border border-solid rounded-full sm:px-12"
            >
              {trans.home.introduction.hero_view_introduction}
            </Button>
          </ButtonAnimation>
        </Stack>
        <motion.div variants={textVariant(0.4)}>
          <Stack direction="column" gap={2} className='mt-10'>
            <Grid container spacing={3} className="items-center">
              <Grid item xs={12}>
                <Typography className='text-base uppercase text-ps-hero-feature-title'>
                  {trans.home.introduction.featured_in}
                </Typography>
              </Grid>
              {featureInMapping.map(obj => (
                <Grid item xs={isMobile ? 4 : 3} key={obj.name}>
                  <Link href={obj.link} className="no-underline" target="_blank">
                    <Image src={obj.icon} alt={obj.name} />
                  </Link>
                </Grid>
              ))}
              <Grid item xs={12}>
                <Typography className='text-base uppercase text-ps-hero-feature-title'>
                  {trans.home.introduction.audited_by}
                </Typography>
              </Grid>
              <Grid item xs={isMobile ? 4 : 3}>
                <Link href="https://propeasy.org/blogs/propeasy-smart-contract-passes-audit" className="no-underline">
                  <Image src={VerichainsIcon} alt="verichains-partner"/>
                </Link>
              </Grid>
            </Grid>
          </Stack>
        </motion.div>
      </Stack>

      <Dialog
        maxWidth={false}
        open={playVideo}
        onClose={() => setPlayVideo(false)}
        data-testid="video-dialog"
      >
        <YoutubeEmbed src={trans.launch.home.video_url} title={trans.launch.home.video_title} />
      </Dialog>
    </>
  )
}

const Introduction = () => {
  const isMobile = useIsMobileDevice()

  const introduction = useMemo(() => {
    return (
      <>
        {isMobile ? (
          <Grid
            container
            className="py-100"
            style={{
              ...styles.heroContainer,
              height: 'auto',
              background: `url(${IntroBackgroundImageMobile.src})`,
              backgroundSize: 'cover',
            }}
          >
            <Grid item sm={12} className="flex items-center px-4 h-[100dvh]">
              <HeroText />
            </Grid>
          </Grid>
        ) : (
          <Box style={styles.heroContainer}>
            <Container className="h-full">
              <Grid container spacing={2} className="pt-20">
                <Grid sm={6} item className="flex items-center h-full">
                  <HeroText />
                </Grid>
              </Grid>
            </Container>
          </Box>
        )}
      </>
    )
  }, [isMobile])

  return <>{introduction}</>
}

export default withNoSsr(SectionWrapper(Introduction, 'home-introduction'))
