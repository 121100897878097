import React from 'react'

import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useQuery } from 'react-query'

import { StatusChip } from '@/components/properties/components/status-item'
import withNoSsr from '@/components/shared/with-no-ssr'
import SkeletonFeaturedList from '@/components/skeleton/featured-list'
import useTrans from '@/root/src/hooks/useTrans'
import { StatusModel } from '@/root/src/model/properties-detail'
import { getProperties } from '@/root/src/utils/apis/property'
import {
  IconName,
  staticImportIcon,
} from '@/root/src/utils/static-import-icon'
import {
  Box,
  Container,
  Grid,
  Paper,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'

interface InfoBoxProps {
  icon: string
  title: string
  value: number | string
  bg: string
  isPrice?: boolean
}

type TokenInfo = {
  token_logo_url: string
  token_name: string
  sale_status: StatusModel
  price: number
  change_24h: number
  title: string
  image_urls?: string[]
  burn_start_at?: string
  burn_end_at?: string
}

interface TokenTableProps {
  tokensList: TokenInfo[]
}

interface TokenInfoCardProps {
  tokenInfo: TokenInfo
}

const formatPriceValue = (value?: number) => value ? `$${value.toLocaleString()}` : '-'

const INFO_BOXES = [
  {
    icon: IconName.PROJECT_ICON,
    title: 'total_projects',
    valueField: 'total_count',
    bg: '#E9F5FB',
  },
  {
    icon: IconName.TOTAL_VALUATION_ICON,
    title: 'total_value',
    valueField: 'tokenized_valuation',
    bg: '#F9F1EF',
    isPrice: true,
  },
]

const InfoBox = ({ icon, title, value, bg, isPrice }: InfoBoxProps) => {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))
  const iconSize = isSmallScreen ? 64 : 72
  const trans = useTrans()

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" className="h-[104px] rounded-lg py-3 px-5" sx={{ backgroundColor: bg }}>
      <Image
        src={staticImportIcon(icon)}
        width={iconSize}
        height={iconSize}
        alt={icon}
      />
      <Stack justifyContent="center" alignItems="flex-end" direction="column" gap={1}>
        <Typography className="font-normal text-base text-right">{trans.home.featured_listing[title]}</Typography>
        <Typography className="font-bold text-2xl text-right">{isPrice ? formatPriceValue(value as number) : value}</Typography>
      </Stack>
    </Stack>
  )
}

const TokenInfoCard = ({ tokenInfo }: TokenInfoCardProps) => {
  const {
    token_logo_url,
    token_name,
    sale_status,
    price,
    change_24h,
    title,
    image_urls,
    burn_start_at,
    burn_end_at,
  } = tokenInfo
  const trans = useTrans()

  return (
    <Grid item xs={18} data-testid="property-card">
      <Link href={`/properties/detail/${token_name}`}>
        <Stack direction="column" gap={2} className="rounded-lg bg-white border p-4 border-[#E8E7E2] shadow-[0_4px_8px_0_#E4E1D940]">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Stack direction="row" gap={1} alignItems="center">
              <Image
                src={token_logo_url}
                width={32}
                height={32}
                alt={`${token_name}-icon`}
              />
              <Typography className="font-bold text-sm pr-3">{token_name}</Typography>
            </Stack>
            <StatusChip status={sale_status} burnStartDate={burn_start_at} burnEndDate={burn_end_at} textSize="xs" />
          </Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Stack gap={0.5}>
              <Typography className="text-[#33393CB2] text-xs">
                {trans.home.featured_listing.price}
              </Typography>
              <Typography className="font-bold text-base">{price ? `$${price}` : '-'}</Typography>
            </Stack>
            <Stack gap={0.5} alignItems="flex-end">
              <Typography className="text-[#33393CB2] text-xs">
                {trans.home.featured_listing.change_24h}
              </Typography>
              <StyledChange24hText change_24h={change_24h} className='font-medium text-base'/>
            </Stack>
          </Stack>
          <Stack gap={2}>
            <Typography className="font-medium text-base">{title}</Typography>
            <Stack direction="row" gap={1.5}>
              {image_urls?.slice(0, 2).map((url: string) => (
                <Image
                  key={url}
                  alt={`${token_name}-property-image`}
                  src={url}
                  width={90}
                  height={64}
                  className="w-[90px] h-[64px] rounded-md object-cover"
                />
              ))}
            </Stack>
          </Stack>
        </Stack>
      </Link>
    </Grid>
  )
}

const TokenInfoHeadRow = styled(TableRow)(() => ({
  '& .MuiTableCell-root': {
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
    color: '#33393C99',
    fontWeight: 600,
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
  },
}))

const TokenInfoRow = styled(TableRow)(() => ({
  '& .MuiTableCell-root': {
    fontSize: '1rem',
    lineHeight: '1.5rem',
    color: '#33393C',
    fontWeight: 500,
    '& .MuiTypography-root': {
      fontWeight: 700,
    },
  },
}))

const StyledChange24hText = ({ change_24h, className }: { change_24h: number, className?: string }) => (
  <Typography className={`${change_24h < 0 ? 'text-[#E33B54]' : 'text-[#2B8501]'} ${className ? className : ''}`}>
    {change_24h ? `${change_24h}%` : '-'}
  </Typography>
)

const TokenInfoTable = ({ tokensList }: TokenTableProps) => {
  const trans = useTrans()
  const router = useRouter()

  return (
    <TableContainer component={Paper} className="mt-8">
      <Table>
        <TableHead className="bg-[#F5F7F9]">
          <TokenInfoHeadRow>
            <TableCell className="min-w-[420px]">{trans.home.featured_listing.token}</TableCell>
            <TableCell>{trans.home.featured_listing.price}</TableCell>
            <TableCell>{trans.home.featured_listing.change_24h}</TableCell>
            <TableCell className="min-w-[90px]">{trans.home.featured_listing.asset}</TableCell>
            <TableCell className="min-w-[250px]"></TableCell>
          </TokenInfoHeadRow>
        </TableHead>
        <TableBody>
          {tokensList.map(({
            token_logo_url,
            token_name,
            sale_status,
            price,
            change_24h,
            title,
            image_urls,
            burn_start_at,
            burn_end_at,
          }: TokenInfo, index: number) => (
            <TokenInfoRow key={index} hover className="hover:cursor-pointer" onClick={() => router.push(`/properties/detail/${token_name}`)}>
              <TableCell>
                <Stack direction="row" alignItems="center" gap={1}>
                  <Image
                    src={token_logo_url}
                    width={40}
                    height={40}
                    alt={`${token_name}-icon`}
                  />
                  <Typography>{token_name}</Typography>
                  <StatusChip status={sale_status} burnStartDate={burn_start_at} burnEndDate={burn_end_at} textSize='sm' />
                </Stack>
              </TableCell>
              <TableCell>{price ? `$${price}` : '-'}</TableCell>
              <TableCell>
                <StyledChange24hText change_24h={change_24h} />
              </TableCell>
              <TableCell>{title}</TableCell>
              <TableCell>
                <Stack gap={1} direction="row">
                  {image_urls?.slice(0, 2).map((url: string) => (
                    <Image
                      key={url}
                      alt="image"
                      src={url}
                      width={108}
                      height={80}
                      className="h-[80px] w-[108px] rounded-xl object-cover"
                    />
                  ))}
                </Stack>
              </TableCell>
            </TokenInfoRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const FeaturedList = () => {
  const { data: dataProperties, isLoading: isFetchingProperties } = useQuery(
    ['fetchFeaturedProperties'],
    async () => getProperties(true),
    {
      refetchOnWindowFocus: false,
    },
  )
  const propertiesData = dataProperties?.data
  const propertiesList = propertiesData?.items || []
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))
  const trans = useTrans()

  if (!isFetchingProperties && !propertiesList.length) return <></>

  return (
    <Box className="bg-[#FCFBF8] py-20" data-testid="featured-properties">
      <Container>
        <Grid container columnSpacing={1} alignItems="center" columns={18} rowSpacing={2}>
          {
            isFetchingProperties ? (
              <SkeletonFeaturedList />
            ) : (
              <>
                <Grid item sm={18} md={8} alignItems="center">
                  <Stack>
                    <Typography className="font-bold text-[32px] leading-[64px]">
                      {trans.home.featured_listing.title}
                    </Typography>
                    <Typography className="font-normal text-base">
                      {trans.home.featured_listing.description}
                    </Typography>
                  </Stack>
                </Grid>
                { INFO_BOXES.map((box, index) => (
                  <Grid item xs={18} md={5} key={index}>
                    <InfoBox {...box} value={propertiesData[box.valueField]} />
                  </Grid>
                )) }
                {  isSmallScreen ? (
                  propertiesList.map((token: TokenInfo, index: number) => (
                    <TokenInfoCard tokenInfo={token} key={index} />
                  ))
                ) : (
                  <TokenInfoTable tokensList={propertiesList} />
                ) }
              </>
            )
          }
        </Grid>
      </Container>
    </Box>
  )
}

export default withNoSsr(FeaturedList)
